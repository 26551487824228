import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { propsToAttributes, withCustomProps } from '../styles/utils';

const StyledLabel = withCustomProps(styled.label`
  cursor: pointer;
  ${({ isBold }) => (isBold ? `font-weight: bold;` : '')}
  ${propsToAttributes}
`);

export const Label = ({
  label,
  required,
  className,
  isBold,
  isHeading,
  children,
  htmlFor,
  ...labelProps
}) => {
  const text = label || children;
  return text ? (
    <StyledLabel
      isBold={isBold}
      className={`control-label ${className}`}
      htmlFor={htmlFor}
      {...labelProps}
    >
      <Wrapper isHeading={isHeading}>
        {text}
        {required && (
          <>
            &nbsp;<abbr title="required">*</abbr>
          </>
        )}
      </Wrapper>
    </StyledLabel>
  ) : null;
};

const Wrapper = ({ isHeading, children }) =>
  isHeading ? <h3>{children}</h3> : <>{children}</>;

Label.defaultProps = {
  className: '',
  isBold: false,
  isHeading: false,
};

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.string,
  isBold: PropTypes.bool,
  isHeading: PropTypes.bool,
  required: PropTypes.bool,
  htmlFor: PropTypes.string,
};

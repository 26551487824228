import styled from 'styled-components';
import { Flex } from './flex';
import { errorRed, lightBlue, lightGray } from './variables';

const INPUT_GAP = '32px';
export const Checkbox = styled.input`
  vertical-align: text-bottom;
`;

export const ToggleWrapper = styled.div`
  padding-top: 10px;
  display: inline-block;
`;

export const disabledStyle = `
    opacity: 0.6;
    cursor: not-allowed !important;
    & * {
      pointer-events: none;
    }
`;

export const ToggleLabel = styled.div`
  padding-left: 10px;
  vertical-align: text-bottom;
  display: inline-block;

  label {
    margin-bottom: 0;
  }
`;

// StyledForm or RowsWrapper should be used as a direct wrapper
// around the FormRow
export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
  ${({ disabled }) => (disabled ? disabledStyle : '')}
  overflow-y: auto;
  overflow-x: hidden;
`;

export const RowsWrapper = styled(Flex)`
  display: flex;
  flex-direction: column;
  gap: 32px;
  ${({ disabled }) => (disabled ? disabledStyle : '')}
`;

// `inputWidth` (as fraction) if we don't want to make input(s) full width
// `singleColumn` force each input on separate "row", useful for overriding default style
// e.g. <FormRow inputWidth={1/4} singleColumn={true} />
export const FormRow = styled(Flex)`
  gap: ${INPUT_GAP};
  & > * {
    width: ${({ inputWidth }) =>
      inputWidth
        ? `calc(( 100% - ( ${Math.round(
            1 / inputWidth
          )} - 1) * ${INPUT_GAP} ) * ${inputWidth})`
        : '100%'};
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
  ${({ singleColumn }) => (singleColumn ? 'flex-direction: column;' : '')}
  label {
    cursor: pointer;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

// receives `checked` as a prop in order to show radio buttons styles properly
export const OptionLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  position: relative;
  margin-right: auto;
  color: ${lightGray};
  & > input {
    opacity: 0;
    margin: 0;
  }
  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    border: 2px solid ${lightGray};
    border-radius: 50%;
  }
  &::after {
    content: '';
    display: ${({ checked }) => (checked ? 'inline-block' : 'none')};
    position: absolute;
    width: 9px;
    height: 9px;
    left: 4px;
    border-radius: 50%;
    background-color: ${lightGray};
  }
`;

export const StyledRadioInput = styled.span`
  position: relative;
  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    border: 2px solid ${lightGray};
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    ${({ checked }) => checked && `border-color: ${lightBlue};`}
  }
  &::after {
    content: '';
    display: ${({ checked }) => (checked ? 'inline-block' : 'none')};
    position: absolute;
    width: 9px;
    height: 9px;
    left: 4px;
    border-radius: 50%;
    background-color: ${lightGray};
    top: 50%;
    transform: translateY(-50%);
    ${({ checked }) => checked && `background: ${lightBlue};`}
  }
`;

export const StyledRadioButtonCard = styled.label`
  display: inline-flex;
  align-items: center;
  gap: 16px;
  text-transform: none;
  min-width: 200px;
  line-height: 2 !important;
  & > *:last-child {
    margin-left: auto;
    margin-right: 18px;
  }
  &,
  &:hover {
    ${({ checked }) =>
      checked && `border-color: ${lightBlue}; background: #eef7fe;`}
  }
  & > span {
    ${({ checked }) => checked && `color: ${lightBlue};`}
  }
`;

// Sometimes we want to be able handle focus event
// e.g. focus on error from the final-form,
// if the type attribute on input element is hidden
// events will be ignored
export const HiddenInput = styled.input`
  height: 0;
  border: none;
  padding: 0;
  position: absolute;
`;

export const HeadingButtonsWrapper = styled(Flex)`
  margin-top: ${({ isHeading }) => (isHeading ? '-20px;' : '-4px;')}
  margin-right: -4px;
  button {
    padding: 4px;
    display: flex;
    align-items: center;
  }
`;

export const ErrorWrapper = styled.span.attrs((props) => ({
  'data-testid': 'error-message',
  ...props,
}))`
  display: ${({ children }) => (children ? 'block' : 'none')};
  margin-top: 5px;
  margin-bottom: 10px;
  color: ${errorRed};
`;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import useVisibility from '../../../lib/use_visibility';
import { PopoverProps, Popover as PopoverComponent } from './popover';

/**
 *  usePopover hook returns object containing:
 *  Popover: a functional component that is responsible for showing the popover,
 *           as well as accompanying trigger elements. Its props are:
 *           trigger: a reacting node that will be initially rendered. Clicking on
 *                    this component will open the popover.
 *           placement:  | 'auto'        | 'auto-start'  | 'auto-end'    |
 *                       | 'top'         | 'top-start'   | 'top-end'     |
 *                       | 'bottom'      | 'bottom-start'| 'bottom-end'  |
 *                       | 'right'       | 'right-start' | 'right-end'   |
 *                       | 'left'        | 'left-start'  | 'left-end'    |
 *           hideArrow: if true, do not render the arrow pointing from popover to the trigger node.
 *                      default value: false
 *           children: react node or html as string to be rendered inside the popover
 *  show: a function that should be used as event listener callback (e.g <el onClick={show} />). It shows the popover when called.
 *        If it is called as/from the onClick callback, the event object should be passed to the show function to prevent
 *        the potential side effect of body onClick listener (see implementation of useVisibility hook).
 *  hide: a function that hides the popover when called.
 *  toggle: an event callback function, executes `show` or `hide` depending on the `shown` value.
 *  shown: boolean,
 *  width: width of the popover el
 * */

export const usePopover = () => {
  const popoverRef = useRef(null);
  const [updatedRef, setUpdatedRef] = useState(null);
  const {
    shown,
    toggleVisibility: toggle,
    show,
    hide,
  } = useVisibility(updatedRef, false, true);

  useEffect(() => {
    if (popoverRef.current && !updatedRef) {
      setUpdatedRef(popoverRef);
    }
  }, [popoverRef.current]); //eslint-disable-line

  const Popover = useCallback(
    (popoverProps) => {
      return (
        <PopoverComponent
          ref={popoverRef}
          shown={shown}
          toggle={toggle}
          {...popoverProps}
        />
      );
    },
    [shown]
  );

  Popover.propTypes = PopoverProps;

  return {
    Popover,
    show,
    hide,
    shown,
    toggle,
  };
};

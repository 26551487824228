import { replaceWhitespace } from '../../../util';

export const buildErrorId = ({ errorText, name, namespace }) => {
  if (!errorText) return '';
  const msg = Array.isArray(errorText) ? errorText.join(' ') : errorText;
  return [namespace, name, replaceWhitespace(msg)].filter(Boolean).join('_');
};

export const generateKeyDownHandler = ({ type, max, tags, setTags }) => {
  // The maxlength attribute is ignored for numeric inputs.
  // We implement it ourselves based on the maximum allowed value.
  const maxLength = max && Math.ceil(Math.log10(max));

  const keyHandlers = {
    integer: intKeyDownHandlerWrapper(maxLength),
    tags: tagsKeyDownHandlerWrapper({ tags, setTags }),
  };

  return keyHandlers[type];
};

const tagsKeyDownHandlerWrapper =
  ({ tags, setTags }) =>
  (event) => {
    const CONTROL_KEYS = new Set(['Enter', ',', 'Tab']);
    const { key, target } = event;

    if (key === 'Backspace' && target.value === '') {
      setTags(tags.slice(0, -1));
    }
    if (
      CONTROL_KEYS.has(key) &&
      target.value !== '' &&
      tags.every((tag) => tag !== target.value)
    ) {
      event.preventDefault();
      setTags([...tags, target.value]);
      target.value = '';
    }
  };

const intKeyDownHandlerWrapper = (maxLength) => (e) => {
  // Codes for control keys that should always be allowed,
  // even when we are programatically restricting input.
  const CONTROL_KEYS = new Set([
    8, // backspace
    9, // tab
    37, // left
    38, // up
    39, // right
    40, // down
    46, // delete
  ]);

  if (!isNaN(Number(e.key))) {
    if (maxLength && e.target.value.length >= maxLength) e?.preventDefault();
  } else if (!CONTROL_KEYS.has(e.keyCode)) {
    e?.preventDefault();
  }
};

export const ERROR_MESSAGE_CODES = {
  REQUIRED: 'field.error_message.required',
  EMAIL: 'field.error_message.email',
  HIGHER: 'field.error_message.higher',
  LOWER: 'field.error_message.lower',
  NOT_LOWER: 'field.error_message.not_lower',
  NOT_HIGHER: 'field.error_message.not_higher',
};

// if we have the multi (used with select) flag set to true
// we are expecting an array of values
const isRequiredValid = (value, multi) =>
  multi
    ? Boolean(value?.length)
    : value === false || value === 0 || Boolean(value);

export const requiredValidation = ({
  value,
  translate,
  customMessage,
  multi,
}) =>
  isRequiredValid(value, multi)
    ? undefined
    : customMessage || translate(ERROR_MESSAGE_CODES.REQUIRED);

const validationsWithRequired =
  ({ validate, translate, customMessage, multi }) =>
  (fieldValue, formValues, field) => {
    const requiredError = requiredValidation({
      value: fieldValue,
      translate,
      customMessage,
      multi,
    });
    if (requiredError) return requiredError;
    return validate ? validate(fieldValue, formValues, field) : undefined;
  };

// returns validation function that can be use with react-final-form Fileds
export const generateValidations =
  ({ required, validate, translate, disabled, customMessage, multi }) =>
  (fieldValue, formValues, field) => {
    if (disabled) return undefined;
    const validations = required
      ? validationsWithRequired({ translate, validate, customMessage, multi })
      : validate;

    return validations ? validations(fieldValue, formValues, field) : undefined;
  };

// extract the error message based on the meta object, passed down by the Field component.
export const getError = ({
  error,
  modifiedSinceLastSubmit,
  submitError,
  touched,
}) => {
  if (!modifiedSinceLastSubmit && submitError) return submitError;
  return !touched ? null : error || submitError;
};

export const objToISODate = (dateObj) =>
  [
    dateObj?.getFullYear(),
    (dateObj.getMonth() + 1).toString().padStart(2, '0'),
    dateObj.getDate().toString().padStart(2, '0'),
  ].join('-');

// we are getting the date value as d.M.yyyy (if re rendering page after the unrelated submit error)
// or yyyy-MM-dd string from ruby form
// we need to transform it to js Date obj.
// it would become redundant once we migrate whole publication form to the react side
export const stringToDate = (dateString) => {
  if (!dateString) return null;

  const isDotFormating = dateString.includes('.');

  if (!isDotFormating) return new Date(dateString);

  var parts = dateString.split('.'); // Split the date string by '.' or '-' characters
  isDotFormating && parts.reverse();

  // Create a new Date object with the parsed day, month, and year
  return new Date(parts.join('-'));
};

export const isEmailValid = (email) =>
  Boolean(
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  );

export const emailValidation = ({ value, translate, customMessage }) =>
  !value || isEmailValid(value)
    ? undefined
    : customMessage || translate(ERROR_MESSAGE_CODES.EMAIL);

export const isHigherThan = (value, condition) => value > condition;

export const higherThanValidation = ({
  value,
  condition,
  translate,
  customMessage,
}) =>
  (value !== 0 && !value) || isHigherThan(value, condition)
    ? undefined
    : customMessage ||
      translate(ERROR_MESSAGE_CODES.HIGHER, { count: condition });

export const notHigherThanValidation = ({
  value,
  condition,
  translate,
  customMessage,
}) =>
  (value !== 0 && !value) || !isHigherThan(value, condition)
    ? undefined
    : customMessage ||
      translate(ERROR_MESSAGE_CODES.NOT_HIGHER, { count: condition });

export const isLowerThan = (value, condition) => value < condition;

export const lowerThanValidation = ({
  value,
  condition,
  translate,
  customMessage,
}) =>
  (value !== 0 && !value) || isLowerThan(value, condition)
    ? undefined
    : customMessage ||
      translate(ERROR_MESSAGE_CODES.LOWER, { count: condition });

export const notLowerThanValidation = ({
  value,
  condition,
  translate,
  customMessage,
}) =>
  (value !== 0 && !value) || !isLowerThan(value, condition)
    ? undefined
    : customMessage ||
      translate(ERROR_MESSAGE_CODES.NOT_LOWER, { count: condition });

export const INPUT_TYPE = {
  INTEGER: 'integer',
  LINK: 'link',
  TEXTAREA: 'textarea',
  TEXT: 'text',
  NUMBER: 'number',
};

export const flattenOptions = (options) =>
  options.length && 'options' in options[0]
    ? options.flatMap((group) => group.options)
    : options;

// A function that help us keep the form state consistent after
// programatic value changes.
// In some cases we have to normalize the provided value (editor component),
// or set the default value ourselves (location select in publication form),
// but we don't want any side-effects that go with making a change
// to a form field (making form touched, dirty...).
export const normalizeInitialValue = ({
  form,
  value,
  name,
  isEqual = (a, b) => a === b,
}) => {
  const { touched, initialValues } = form.getState();
  // we have to calculate our own pristine since some fields are
  // formated automatically making fields `ditrty` in form context
  const pristine = Object.values(touched).every((isToched) => !isToched);

  // if the form is not touched by the user and the initial value
  // of the given field is different from the normalized value we
  // reinitialize the form with the normalized value, making it
  // not dirty, aka pristine again in the Form context
  if (pristine && !isEqual(initialValues[name], value)) {
    form.reset({ ...initialValues, [name]: value });
  }
};

// link fields by defaut have value "http://". we want to make sure that
// if the value is indeed "http://" we treat it as an empty field
const normalizeLinkInputValue = (inputString) => {
  const trimmedLink = inputString.trim();
  return trimmedLink === 'https://' ? '' : trimmedLink;
};

// a helper function that takes for values, and a list of link field names,
// ad return normalized values. In this case, we remove link strings that
// consist only from "https://"
export const normalizeLinkValues = (values, linkFields) => {
  const normalizedValues = { ...values };
  linkFields.forEach((field) => {
    const urlString = normalizedValues[field];
    if (urlString) {
      normalizedValues[field] = normalizeLinkInputValue(urlString);
    }
  });
  return normalizedValues;
};

import React from 'react';
import PropTypes from 'prop-types';

import { usePopover } from './popover';
import Icon from './icon';
import { placeholderGray } from './styles/variables';

const IconWithPopover = ({
  children,
  color,
  content,
  triggerId,
  icon,
  iconSize,
  placement,
}) => {
  const { Popover } = usePopover();
  return (
    <Popover
      triggerId={triggerId}
      trigger={<Icon color={color} size={iconSize} name={icon} />}
      placement={placement}
    >
      {content || children}
    </Popover>
  );
};

IconWithPopover.defaultProps = {
  color: placeholderGray,
  cssClasses: '',
  icon: 'help',
  iconSize: 24,
  trigger: 'focus',
};

IconWithPopover.propTypes = {
  color: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.elementType,
    PropTypes.string,
  ]).isRequired,
  cssClasses: PropTypes.string,
  triggerId: PropTypes.string,
  icon: PropTypes.string.isRequired,
  iconSize: PropTypes.number,
  placement: PropTypes.string,
};

export default IconWithPopover;

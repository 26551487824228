/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/role-has-required-aria-props */

import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';

import useI18n from '../../../lib/use_i18n';
import { InputWrapper } from './input_wrapper';
import { flattenOptions, getError } from './utils';
import { Field } from './field';
import {
  borderLightGray,
  lightBlue,
  lightGray,
  modalZIndex,
} from '../styles/variables';
import styled from 'styled-components';
import { HiddenInput } from '../styles/form';

const defaultBoxShadow = `inset 0 -1px 0 ${borderLightGray}`;
const customStyles = {
  control: (provided) => ({
    ...provided,
    background: 'transparent',
    borderTopStyle: 'none',
    borderLeftStyle: 'none',
    borderRightStyle: 'none',
    borderRadius: 'none',
    border: 'none',
    boxShadow: defaultBoxShadow,
  }),
  indicatorSeparator: () => ({}),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 0,
    '&>div': {
      color: lightGray,
    },
  }),
  // z-index higher than modla overlay
  menuPortal: (provided) => ({ ...provided, zIndex: modalZIndex + 5 }),
  input: (provided) => ({
    ...provided,
    '&>input:focus, &>input': {
      boxShadow: 'none !important',
    },
  }),
  menuList: (base, state) => ({
    ...base,
    padding: state?.selectProps?.dropdownHint ? '4px 0 0 0' : '4px 0',
  }),
};

const customStylesWithError = (hasError) => ({
  ...customStyles,
  control: (provided) => ({
    ...customStyles.control(provided),
    boxShadow: hasError ? 'inset 0 -2px 0 #e51c23' : defaultBoxShadow,
    '&:focus-within': {
      boxShadow: !hasError ? `inset 0 -2px 0 ${lightBlue}` : defaultBoxShadow,
    },
  }),
});

const scrollbarStyle = (showScrollbar) =>
  showScrollbar
    ? {
        menuList: (base) => ({
          ...base,
          height: '250px',

          '::-webkit-scrollbar': {
            width: '9px',
          },
          '::-webkit-scrollbar-track': {
            background: 'lightgray',
          },
          '::-webkit-scrollbar-thumb': {
            background: '#888',
          },
          '::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        }),
      }
    : {};

export const generateStyles = ({ showScrollbar, hasError }) => {
  return {
    ...customStylesWithError(hasError),
    ...scrollbarStyle(showScrollbar),
  };
};

// It only exists because we want to have correct roles on elements
export const Option = (props) => (
  <components.Option {...props}>
    <div data-value={props.value} role="option">
      {props.children}
    </div>
  </components.Option>
);

const DropdownHint = styled.small`
  position: sticky;
  display: flex;
  bottom: 0;
  padding: 0 8px;
  background: white;
  color: ${lightGray};
  box-shadow: 0 -5px 5px -5px ${borderLightGray};
`;

export const MenuList = (props) => (
  <components.MenuList
    {...props}
    innerProps={{
      ...props.innerProps,
      'data-testid': 'select-options-wrapper',
    }}
  >
    {props.children}
    <DropdownHint>
      {props?.selectProps?.dropdownHint && props?.selectProps?.dropdownHint}
    </DropdownHint>
  </components.MenuList>
);

/**
 * Renders a Dropdown component based on react-select.
 *
 *   <SelectField
 *     label="label"
 *     required="required"
 *     placeholder="placeholder"
 *     value={{label: 'label', value: 'value'}}
 *     options={[{label: 'label1', value: value1}, {label: 'label2', value: value2}]}
 *     errorText="error"
 *     onChange={fn()}
 *   />
 *
 */
const SelectField = ({
  clearable,
  customClassName,
  customSelectStyles,
  dropdownHint,
  disabled,
  errorText,
  hint,
  hintId,
  id,
  label,
  labelProps,
  maxValues,
  multi,
  name,
  namespace,
  onChange,
  options,
  placeholder,
  required,
  searchable,
  showScrollbar,
  value,
  ...props
}) => {
  const { translate } = useI18n();
  const [selected, setSelected] = useState(value);

  const updateSelected = (selected) => {
    if (Array.isArray(selected)) {
      if (selected.length <= maxValues) {
        setSelected(selected);
      }
    } else {
      setSelected(selected);
    }
  };

  /**
   * There is no out of the box way to style the scrollbar, so in case we want to show
   * the scrollbar, we can use these custom pre-defined css rules by using `showScrollbar` prop.
   */

  useEffect(() => {
    // we cannot set an empty string or null because of that bug
    // https://github.com/final-form/react-final-form/issues/430
    onChange(selected || undefined);
  }, [selected]);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const styles = customSelectStyles
    ? customSelectStyles
    : generateStyles({ hasError: Boolean(errorText), showScrollbar });

  // id for search input field that is used
  // to switch focus from the HiddenInput to search field,
  // utilizing final-forms default mechanism
  const selectInputId = `select_input_${name}`;
  return (
    <InputWrapper
      disabled={disabled}
      errorText={errorText}
      labelProps={{ label, required, ...labelProps, htmlFor: selectInputId }}
      name={name}
      namespace={namespace}
      hint={hint}
      hintId={hintId}
    >
      <Select
        blurInputOnSelect={true}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        inputId={selectInputId}
        dropdownHint={dropdownHint}
        aria-label={label}
        className={customClassName}
        components={{ Option, MenuList }}
        id={id || `select_container_${name}`}
        isClearable={clearable}
        isDisabled={disabled}
        isMulti={multi}
        isSearchable={searchable}
        onChange={updateSelected}
        options={options}
        placeholder={
          placeholder ? placeholder : translate('forms.dropdown.placeholder')
        }
        styles={styles}
        // update component after value is changed to a falsy value
        key={`select_key__${selected}`}
        value={selected}
        {...props}
      />
      <HiddenInput
        readOnly
        id={name}
        name={name}
        value={value?.value}
        onFocus={() => {
          // handles focus-on-error from react-final-form
          // redirects focus to the select input
          document.getElementById(selectInputId)?.focus();
        }}
      />
    </InputWrapper>
  );
};

SelectField.defaultProps = {
  disabled: false,
  multi: false,
  clearable: false,
  maxValues: 3,
};

SelectField.propTypes = {
  clearable: PropTypes.bool,
  customClassName: PropTypes.string,
  customSelectStyles: PropTypes.object,
  disabled: PropTypes.bool,
  dropdownHint: PropTypes.string,
  errorText: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  maxValues: PropTypes.number,
  multi: PropTypes.bool,
  name: PropTypes.string,
  namespace: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
    PropTypes.array,
  ]).isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  searchable: PropTypes.bool,
  showScrollbar: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
};

/**
 * An adapter component allowing SelectField to be easily used
 * as a react-final-form component.
 *
 * <Field name="something"
 *        component={SelectField.FormAdapter}
 *        options={...} required={...} label={...} placeholder={...} />
 */
// eslint-disable-next-line
SelectField.FormAdapter = (props) => {
  const onChange = (val) => {
    // a callback set by final-form
    props.input.onChange(val);
    // a custom callback which might be passed by the component
    props.onChange && props.onChange(val);
  };

  return (
    <SelectField
      {...props}
      name={props.input.name}
      value={props.input.value}
      errorText={getError(props.meta)}
      onChange={onChange}
      onFocus={props?.input?.onFocus} // we have to pass callback from Form Field in order to
      onBlur={props?.input?.onBlur} // keep the field state consistent (pristine, touched, dirty...)
    />
  );
};

// TODO replace FormAdapter instances with FormSelect

/**
 * react-select component calls onChange with the option object e.g.
 * { value, label } which, altho might be useful in some cases, differs from the default
 * select behavior. The expected parameter of the onChange callback is usually just
 * the `value` string. This discrepancy will force us to write unnecessary boilerplate code,
 * formatting the select values, for example in `submit` functions.
 * This component is final form wrapper to mitigate the non-standard behavior.
 **/
export const FormSelect = ({
  disabled,
  label,
  labelProps,
  name,
  namespace,
  options,
  required,
  selectProps,
  hint,
  hintId,
  id,
  placeholder,
  ...props
}) => {
  return (
    <Field
      required={required}
      disabled={disabled}
      format={(id) => {
        const flatOptions = flattenOptions(options);
        return flatOptions.find(({ value }) => value === id);
      }}
      parse={(option) => option?.value || ''}
      name={name}
      {...props}
    >
      {({ input, meta }) => {
        return (
          <SelectField
            disabled={disabled}
            errorText={getError(meta)}
            hint={hint}
            hintId={hintId}
            labelProps={{ label, required, ...labelProps }}
            name={name}
            namespace={namespace}
            options={options}
            required={required}
            value={input.value}
            id={id}
            placeholder={placeholder}
            {...input}
            {...selectProps}
          />
        );
      }}
    </Field>
  );
};

FormSelect.propTypes = {
  ...Field.propTypes,
  disabled: PropTypes.bool,
  labelProps: InputWrapper.propTypes.labelProps,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  namespace: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
    PropTypes.array,
  ]).isRequired,
  required: PropTypes.bool,
  selectProps: PropTypes.shape(SelectField.propTypes),
};

export default SelectField;

import React from 'react';
import PropTypes from 'prop-types';

import { Label } from './label';
import { buildErrorId } from './utils';
import styled from 'styled-components';
import { disabledStyle, HeadingButtonsWrapper } from '../styles/form';
import { Flex } from '../styles/flex';
import IconWithPopover from '../icon_with_popover';

const StyledInputWrapper = styled.div`
  position: relative;
  ${({ disabled }) => (disabled ? disabledStyle : '')}
`;

const StyledHeading = styled(Flex)`
  height: 28px;
  margin-bottom: 5px;
  & > * {
    margin-bottom: 0;
  }
`;

export const InputWrapper = ({
  children,
  className,
  disabled,
  errorText,
  labelProps,
  hint,
  hintId,
  name,
  namespace,
  warningText,
}) => {
  return (
    <StyledInputWrapper
      disabled={disabled}
      className={generateClassName({ className, errorText, warningText })}
      data-testid={`input-wrapper_${name}`}
    >
      {labelProps?.label ? (
        <StyledHeading alignItems="baseline" justifyContent="space-between">
          <Label htmlFor={name} {...labelProps} />
          {hint ? (
            <HeadingButtonsWrapper isHeading={labelProps?.isHeading}>
              <IconWithPopover triggerId={hintId} content={hint} />
            </HeadingButtonsWrapper>
          ) : null}
        </StyledHeading>
      ) : null}
      {children}
      <span
        className="help-block"
        data-inputname={name}
        data-trackingid={buildErrorId({ errorText, name, namespace })}
      >
        {errorText || warningText}
      </span>
    </StyledInputWrapper>
  );
};

InputWrapper.defaultProps = {
  className: '',
  namespace: '',
};

InputWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  hint: PropTypes.string,
  hintId: PropTypes.string,
  labelProps: PropTypes.shape(Label.propTypes),
  name: PropTypes.string,
  namespace: PropTypes.string,
};

const generateClassName = ({ className, errorText, warningText }) => {
  let classes = className ? className : '';

  if (errorText) {
    classes += ' has-error';
  } else if (warningText) {
    classes += ' has-warning';
  }

  return classes;
};

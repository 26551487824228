import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import useI18n from '../../lib/use_i18n';
import { useCookies } from 'react-cookie';
import { Flex } from './styles/flex';
import styled from 'styled-components';

const materialIconClass = (icon) => {
  const codes = {
    success: 'check_circle',
    warning: 'error_outline',
    error: 'highlight_off',
    info: 'info',
  };

  return codes[icon];
};

const ButtonContainer = styled.div`
  margin-left: auto;
  ${({ addRightMargin }) => (addRightMargin ? 'margin-right: 24px;' : '')}
`;

const HideButton = styled.button`
  position: absolute;
  top: 8px;
  right: 0;
`;

const Notification = ({
  actionButtonId,
  buttonAction, // a click event handler
  buttonLink,
  buttonTarget,
  buttonText,
  className,
  closeButtonId,
  cookieName,
  forceMultiline,
  hideActionButton,
  id,
  kind,
  marginBottom,
  marginTop,
  showCloseButton,
  text,
  title,
}) => {
  const prefixedCookieName = `hideNotification-${
    cookieName || closeButtonId || id || title
  }`;

  const { translate } = useI18n();

  const titleRef = useRef();
  const descriptionRef = useRef();
  const buttonRef = useRef();

  const [cookies, setCookie] = useCookies();

  const hideNotification = () => {
    setShow(false);
    setCookie(`${prefixedCookieName}`, 1);
  };

  const hideNotificationCookiePresent = () => {
    return !!cookies[prefixedCookieName];
  };
  const [show, setShow] = useState(!hideNotificationCookiePresent());
  const [multilineClass, setMultilineClass] = useState('');

  /**
   * It's not possible from CSS to split the content in 3 lines (title / text / button) when
   * the width is big because it's related to content, not to window size, it can only make
   * it responsive by moving first or last item below.
   * To achieve this effect we need to verify if css has moved one element below others,
   * in this case we can add class to customize the layout
   */
  useEffect(() => {
    const titleEl = titleRef.current;
    const descriptionEl = descriptionRef.current;
    const buttonEl = buttonRef.current;

    const titleY = titleEl ? titleEl.offsetTop : null;
    const descriptionY = descriptionEl ? descriptionEl.offsetTop : null;
    const buttonY = buttonEl ? buttonEl.offsetTop : null;

    const elementsNotAligned = (titleY, descriptionY, buttonY) => {
      // Description is below title
      if (titleY && descriptionY && titleY < descriptionY) {
        return true;
      }

      // Button is below title
      if (titleY && buttonY && titleY < buttonY) {
        return true;
      }

      // Button is below text
      if (descriptionY && buttonY && descriptionY < buttonY) {
        return true;
      }
    };

    if (forceMultiline || elementsNotAligned(titleY, descriptionY, buttonY)) {
      setMultilineClass('multiline');
    }
  }, [titleRef, descriptionRef, buttonRef]);

  const handleButtonClick = () => {
    buttonAction && buttonAction();
  };

  const notificationLink = () => (buttonLink ? buttonLink : '#');

  const processedText = () => {
    const attrs = {
      ref: descriptionRef,
      className: `description with-title`,
      id: 'notification-description',
      role: 'note',
    };

    if (typeof text === 'function') {
      return <div {...attrs}>{text()}</div>;
    } else {
      return <div {...attrs} dangerouslySetInnerHTML={{ __html: text }}></div>;
    }
  };

  return show ? (
    <Flex
      position="relative"
      className={`notification ${multilineClass} ${kind} ${className || ''}`}
      id={id}
      role="alert"
      marginTop={marginTop}
      marginBottom={marginBottom}
      alignItems="center"
    >
      <div className="icon-container">
        <span className="material-symbols-outlined">
          {materialIconClass(kind)}
        </span>
      </div>

      <div className="content" id="notification-content">
        <div
          className="title"
          id="notification-title"
          ref={titleRef}
          role="heading"
          aria-level="3"
        >
          {title || translate(`notifications.default_title.${kind}`)}
        </div>

        {processedText()}
      </div>
      {!hideActionButton && (buttonAction || buttonLink) && (
        <ButtonContainer addRightMargin={showCloseButton}>
          <a
            className="button btn"
            ref={buttonRef}
            id={actionButtonId}
            href={notificationLink()}
            target={buttonTarget}
            onClick={handleButtonClick}
          >
            {buttonText}
          </a>
        </ButtonContainer>
      )}

      {showCloseButton && (
        <HideButton
          className="btn-transparent"
          onClick={() => hideNotification()}
        >
          <span
            id={closeButtonId || 'hide-notification-button'}
            className="material-symbols-outlined icon"
          >
            close
          </span>
        </HideButton>
      )}
    </Flex>
  ) : null;
};

Notification.defaultProps = {
  actionButtonId: 'notification-action-button',
};

Notification.propTypes = {
  actionButtonId: PropTypes.string,
  buttonAction: PropTypes.func,
  buttonLink: PropTypes.string,
  buttonTarget: PropTypes.string,
  className: PropTypes.string,
  closeButtonId: PropTypes.string,
  cookieName: PropTypes.string,
  forceMultiline: PropTypes.bool,
  hideActionButton: PropTypes.bool,
  id: PropTypes.string,
  kind: PropTypes.oneOf(['info', 'warning', 'error', 'success']).isRequired,
  marginBottom: PropTypes.string,
  marginTop: PropTypes.string,
  showCloseButton: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  title: PropTypes.string,
  buttonText: (props) => {
    if (
      ((props.buttonAction || props.buttonLink) && !props.buttonText) ||
      (!props.buttonAction && !props.buttonLink && props.buttonText)
    ) {
      return new Error(
        `'buttonText' and 'buttonAction' props must be present to include a button in the notification`
      );
    }

    if (props.buttonAction && props.buttonLink) {
      return new Error(
        `'buttonAction' and 'buttonLink' props can't work together, only one of them must be used`
      );
    }
  },
};

export default Notification;
